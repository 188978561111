<template>
  <el-row :gutter="20" style="padding-top:20px">
    <el-col :xs="18" :sm="18">
      <el-form-item style="padding-bottom: 5px" :error="studentEmailError">
        <el-input
          v-model="email"
          @input="isValidate = false"
          @blur="checkStudentEmailAndEmit"
          :placeholder="$t('parentDetail.studentEmail')"
        >
          <div
            slot="suffix"
            class="input-icon icon-check"
            :style="
              colorChangeIsValid
                ? 'background-color: #4CAF50;'
                : 'background-color: white;'
            "
          ></div>
        </el-input>
      </el-form-item>
    </el-col>
    <el-col :xs="4" :sm="4">
      <el-button
        @click="delRow(pushable_id)"
        icon="el-icon-minus"
        plain
      ></el-button>
    </el-col>
  </el-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: function() {
    return {
      email: "",
      studentEmailError: "",
      colorChangeIsValid: false,
      isValidate: false,
      newChildrenList: this.childrenList
    };
  },
  props: ["pushable_id", "childrenList"],
  methods: {
    delRow(id) {
      this.$emit("remove", id);
    },
    async checkStudentEmailAndEmit() {
      if (!this.email) {
        this.studentEmailError = "";
        this.colorChangeIsValid = false;
        const obj = this.newChildrenList.find(x => x.id === this.pushable_id);
        obj.email = "";
        this.$emit("dataFromChild", [this.pushable_id, this.newChildrenList]);
        return;
      }
      if (this.isValidate) return;
      await this.$store.dispatch("registerDetails/checkStudentEmail", {
        email: this.email
      });
      if (this.isValidStudentEmail) {
        // 如果驗證成功(資料庫中存在)
        const res = this.newChildrenList.find(x => x.email === this.email);
        if (res) {
          // 如果輸入 email 已經出現在其他欄位
          this.studentEmailError = this.$t("parentDetail.emailExistError");
          this.colorChangeIsValid = false;
          const obj = this.newChildrenList.find(x => x.id === this.pushable_id);
          obj.email = "";
          this.$emit("dataFromChild", [this.pushable_id, this.newChildrenList]);
        } else {
          // 成功
          this.studentEmailError = null;
          this.colorChangeIsValid = true;
          this.isValidate = true;
          const obj = this.newChildrenList.find(x => x.id === this.pushable_id);
          obj.email = this.email;
          this.$emit("dataFromChild", [this.pushable_id, this.newChildrenList]);
        }
      } else {
        // 如果 email 驗證失敗(資料庫中不存在)
        this.studentEmailError = this.$t("parentDetail.findEmailError");
        this.colorChangeIsValid = false;
        const obj = this.newChildrenList.find(x => x.id === this.pushable_id);
        obj.email = this.email;
        this.$emit("dataFromChild", [this.pushable_id, this.newChildrenList]);
      }
    }
  },
  computed: {
    ...mapState("registerDetails", ["isValidStudentEmail"])
  },
  watch: {
    childrenList: {
      immediate: true,
      deep: true,
      handler(newData) {
        this.newChildrenList = newData;
      }
    }
  }
};
</script>

<style scoped>
.input-icon {
  width: 25px;
  height: 25px;
  margin-top: 7px;
  background-size: cover;
}
.icon-check {
  mask: url("../../assets/svg/check-circle.svg") no-repeat;
}
</style>
