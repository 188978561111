<template>
  <div class="container" style="margin-top: 20px">
    <div class="titleStyle">
      <h1 class="primaryColor" style="font-size: 30px;">
        創建賬號
      </h1>
      <el-divider></el-divider>
    </div>
    <div>
      <el-card class="box-card">
        <div>
          <div style="margin:0">
            <el-form
              :model="newUser"
              :rules="rules"
              ref="profile"
              label-width="90px"
            >
              <el-form-item :label="$t('registerDetail.email')" prop="email">
                <el-input
                  :placeholder="$t('registerDetail.email')"
                  v-model="newUser.email"
                >
                  <div
                    slot="prefix"
                    class="input-icon icon-email"
                    :style="
                      colorChangeEmail
                        ? 'background-color: #42a16a;'
                        : 'background-color: #757575;'
                    "
                  ></div>
                </el-input>
              </el-form-item>

              <el-row :gutter="20">
                <el-col :md="12">
                  <el-form-item
                    :label="$t('registerDetail.firstName')"
                    prop="first_name"
                  >
                    <el-input
                      :placeholder="$t('registerDetail.firstNamePlaceholder')"
                      v-model="newUser.first_name"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="12">
                  <el-form-item
                    :label="$t('registerDetail.lastName')"
                    prop="last_name"
                  >
                    <el-input
                      :placeholder="$t('registerDetail.lastNamePlaceholder')"
                      v-model="newUser.last_name"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item prop="type" label="身份">
                <el-radio-group v-model="type">
                  <el-radio-button
                    :label="item.value"
                    v-for="(item, index) in types"
                    :key="index"
                  >
                    {{ $t(item.label) }}
                  </el-radio-button>
                </el-radio-group>
              </el-form-item>
              <br />
              <template v-if="type">
                <el-row>
                  <el-col>
                    <h1 class="titleLine primaryColor">
                      {{ $t("studentDetail.requiredInformation") }}
                    </h1>
                  </el-col>
                </el-row>
                <el-row v-if="type === 'student'">
                  <el-col>
                    <el-form-item :label="$t('studentDetail.AccountType')">
                      <el-radio-group
                        style="width: 100%"
                        :value="newUser.counseling_schools_type"
                        @input="
                          value => (newUser.counseling_schools_type = value)
                        "
                      >
                        <el-radio :label="1">{{
                          $t("studentDetail.toCollege")
                        }}</el-radio>
                        <el-radio :label="2">{{
                          $t("studentDetail.toGradSchool")
                        }}</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <!-- *****School***** -->
                    <el-form-item
                      :label="$t('studentDetail.school')"
                      prop="high_school"
                      required
                    >
                      <el-input
                        :placeholder="$t('studentDetail.school')"
                        v-model="newUser.high_school"
                        @focus="colorChangeSchool = true"
                        @blur="colorChangeSchool = false"
                      >
                        <div
                          slot="prefix"
                          class="input-icon icon-domain"
                          :style="
                            colorChangeSchool
                              ? 'background-color: #42a16a;'
                              : 'background-color: #757575;'
                          "
                        ></div>
                      </el-input>
                    </el-form-item>

                    <!-- *****Grade level***** -->
                    <el-form-item
                      :label="$t('studentDetail.gradeLevel')"
                      style="padding-top: 10px"
                      required
                    >
                      <GradeLevelSelector
                        :value="graduateSelected"
                        @change="
                          value => {
                            graduateSelected = value;
                          }
                        "
                      />
                    </el-form-item>

                    <!-- *****Phone***** -->
                    <el-form-item
                      :label="$t('studentDetail.phone')"
                      prop="phone"
                      style="padding-top: 10px"
                    >
                      <el-input
                        :placeholder="$t('studentDetail.phone')"
                        v-model="newUser.phone"
                        @focus="colorChangePhone = true"
                        @blur="colorChangePhone = false"
                      >
                        <div
                          slot="prefix"
                          class="input-icon icon-phone"
                          :style="
                            colorChangePhone
                              ? 'background-color: #42a16a;'
                              : 'background-color: #757575;'
                          "
                        ></div>
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row v-if="type === 'parent'">
                  <el-col>
                    <el-form-item
                      :label="$t('studentDetail.phone')"
                      prop="phone"
                      style="padding-top: 10px"
                    >
                      <el-input
                        :placeholder="$t('studentDetail.phone')"
                        v-model="newUser.phone"
                        @focus="colorChangePhone = true"
                        @blur="colorChangePhone = false"
                      >
                        <div
                          slot="prefix"
                          class="input-icon icon-phone"
                          :style="
                            colorChangePhone
                              ? 'background-color: #42a16a;'
                              : 'background-color: #757575;'
                          "
                        ></div>
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row style="padding-top: 20px">
                  <el-col>
                    <h1 class="titleLine primaryColor">
                      {{ $t("parentDetail.optionalInformation") }}
                    </h1>
                  </el-col>
                </el-row>

                <!-- *****Facebook***** -->
                <el-form-item label="Facebook" style="padding-top: 30px">
                  <el-input
                    placeholder="Facebook"
                    v-model="newUser.facebook_id"
                    @focus="colorChangeFacebook = true"
                    @blur="colorChangeFacebook = false"
                  >
                    <div
                      slot="prefix"
                      class="input-icon icon-facebook"
                      :style="
                        colorChangeFacebook
                          ? 'background-color: #42a16a;'
                          : 'background-color: #757575;'
                      "
                    ></div>
                  </el-input>
                </el-form-item>

                <!-- *****Line***** -->
                <el-form-item label="Line" style="padding-top: 10px">
                  <el-input
                    placeholder="Line"
                    v-model="newUser.line_id"
                    @focus="colorChangeLine = true"
                    @blur="colorChangeLine = false"
                  >
                    <div
                      slot="prefix"
                      class="input-icon icon-line"
                      :style="
                        colorChangeLine
                          ? 'background-color: #42a16a;'
                          : 'background-color: #757575;'
                      "
                    ></div>
                  </el-input>
                </el-form-item>

                <!-- *****Wechat***** -->
                <el-form-item label="Wechat" style="padding-top: 10px">
                  <el-input
                    placeholder="Wechat"
                    v-model="newUser.wechat_id"
                    @focus="colorChangeWechat = true"
                    @blur="colorChangeWechat = false"
                  >
                    <div
                      slot="prefix"
                      class="input-icon icon-wechat"
                      :style="
                        colorChangeWechat
                          ? 'background-color: #42a16a;'
                          : 'background-color: #757575;'
                      "
                    ></div>
                  </el-input>
                </el-form-item>

                <!-- *****Skype***** -->
                <el-form-item label="Skype" style="padding-top: 10px">
                  <el-input
                    placeholder="Skype"
                    v-model="newUser.skype_id"
                    @focus="colorChangeSkype = true"
                    @blur="colorChangeSkype = false"
                  >
                    <div
                      slot="prefix"
                      class="input-icon icon-skype"
                      :style="
                        colorChangeSkype
                          ? 'background-color: #42a16a;'
                          : 'background-color: #757575;'
                      "
                    ></div>
                  </el-input>
                </el-form-item>

                <template v-if="type === 'parent'">
                  <el-row style="padding-top:20px">
                    <el-col style="display:inline-box">
                      <h1 class="titleLine primaryColor">
                        {{ $t("parentDetail.connectStudent") }}
                      </h1>
                    </el-col>
                  </el-row>

                  <el-row :gutter="20" style="padding-top:30px">
                    <el-col :xs="18" :sm="18" style="padding-bottom:10px">
                      <el-form-item
                        style="margin-bottom: 10px"
                        :error="studentEmailError"
                      >
                        <el-input
                          v-model="email"
                          @input="isValidate = false"
                          @blur="checkStudentEmail"
                          :placeholder="$t('parentDetail.studentEmail')"
                        >
                          <div
                            slot="suffix"
                            class="input-icon icon-check"
                            :style="
                              colorChangeIsValid
                                ? 'background-color: #4CAF50;'
                                : 'background-color: white;'
                            "
                          ></div>
                        </el-input>
                      </el-form-item>
                    </el-col>

                    <el-col :xs="4" :sm="4">
                      <el-button
                        class="dynamic"
                        @click="add('ParentDetailChild')"
                        icon="el-icon-plus"
                        plain
                      ></el-button>
                    </el-col>
                  </el-row>
                  <component
                    v-for="item in childrenList"
                    :is="item.component"
                    :key="item.id"
                    :pushable_id="item.id"
                    :childrenList="childrenList"
                    @remove="del"
                    @dataFromChild="update"
                  ></component>
                </template>

                <el-row>
                  <el-col>
                    <div align="center" justify="center">
                      <el-button
                        type="primary"
                        style="width: 50%"
                        @click="register(type)"
                      >
                        {{ $t("studentDetail.submit") }}
                      </el-button>
                    </div>
                  </el-col>
                </el-row>
              </template>
            </el-form>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { GradeLevelSelector } from "@/components/selector";
import formMixin from "@/mixins/form";
import userApi from "@/apis/user";
import gradeLevelMixin from "@/mixins/gradeLevel";
import moment from "moment";
import ParentDetailChild from "@/components/roleBinding/ParentDetailChild";

export default {
  components: {
    GradeLevelSelector,
    ParentDetailChild
  },
  mixins: [formMixin, gradeLevelMixin],
  async mounted() {},
  data() {
    return {
      type: null,
      colorChangeEmail: false,
      colorChangeSchool: false,
      colorChangePhone: false,
      colorChangeAddress: false,
      colorChangeFacebook: false,
      colorChangeLine: false,
      colorChangeWechat: false,
      colorChangeSkype: false,
      colorChangeIsValid: false,
      newUser: {
        username: "",
        first_name: "",
        last_name: "",
        chinese_name: "",
        counseling_schools_type: 1,
        gender: null,
        is_active: 1,
        phone_country_code: null,
        phone: "",
        home_phone_country_code: null,
        home_phone: "",
        address: null,
        photo_url: "",
        wechat_id: "",
        line_id: "",
        facebook_id: null,
        skype_id: "",
        lang: "tw",
        name: "",
        email: "",
        email_verified_at: null,
        finish_bind: 1,
        timezone: "Asia/Taipei",
        last_ip: "61.216.157.181",
        is_enrollment_register: 0,
        created_at: "",
        updated_at: "",
        account_balance: "0.00",
        account_balance_currency: "TWD",
        default_currency: "TWD",
        student_ids: [],
        first_role: "student",
        all_roles: []
      },
      types: [
        { label: "registerDetail.student", value: "student" },
        { label: "registerDetail.parent", value: "parent" }
      ],
      graduateSelected: "",
      studentEmailError: null,
      email: "",
      childrenList: [
        {
          id: 0,
          email: ""
        }
      ]
    };
  },
  methods: {
    add: function(component) {
      this.childrenList.push({
        id: this.id,
        component: component,
        email: ""
      });

      this.id++;
    },
    del: function(id) {
      let index = this.childrenList.findIndex(f => f.id === id);
      this.childrenList.splice(index, 1);
    },
    update(value) {
      // const curId = value[0];
      // const obj = this.childrenList.find(x => x.id === curId);
      this.ChildrenList = value[1];
    },
    async checkStudentEmail() {
      if (!this.email) {
        this.studentEmailError = "";
        this.colorChangeIsValid = false;
        this.childrenList[0].email = "";
        return;
      }
      if (this.isValidate) return;
      await this.$store.dispatch("registerDetails/checkStudentEmail", {
        email: this.email
      });

      if (this.isValidStudentEmail) {
        const res = this.childrenList.find(x => x.email === this.email);
        if (res) {
          this.studentEmailError = this.$t("parentDetail.emailExistError");
          this.colorChangeIsValid = false;
          this.childrenList[0].email = "";
        } else {
          this.studentEmailError = "";
          this.colorChangeIsValid = true;
          this.childrenList[0].email = this.email;
          this.isValidate = true;
        }
      } else {
        this.studentEmailError = this.$t("parentDetail.findEmailError");
        this.colorChangeIsValid = false;
        this.childrenList[0].email = this.email;
      }
    },
    async register(type) {
      if (type === "student") {
        await this.studentSubmitForm();
      }
      if (type === "parent") {
        await this.parentSubmitForm();
      }
      this.$message({
        message: "創建成功！",
        type: "success"
      });
      this.$router.go(-1);
    },
    async studentSubmitForm() {
      const user = {
        email: this.newUser.email,
        first_name: this.newUser.first_name,
        last_name: this.newUser.last_name,
        high_school: this.newUser.high_school,
        high_school_graduation_year: this.graduateSelected,
        living: this.newUser.living,
        phone: this.newUser.phone,
        facebook_id: this.newUser.facebook_id,
        line_id: this.newUser.line_id,
        skype_id: this.newUser.skype_id,
        wechat_id: this.newUser.wechat_id
      };
      await userApi.quickRegisterStudent(user);
    },
    async parentSubmitForm() {
      const curEmails = [];
      this.childrenList.forEach(x => {
        if (x.email !== "") {
          curEmails.push(x.email);
        }
      });
      const user = {
        email: this.newUser.email,
        first_name: this.newUser.first_name,
        last_name: this.newUser.last_name,
        phone: this.newUser.phone,
        facebook_id: this.newUser.facebook_id,
        line_id: this.newUser.line_id,
        skype_id: this.newUser.skype_id,
        wechat_id: this.newUser.wechat_id,
        student_emails: curEmails
      };
      await userApi.quickRegisterParent(user);
    }
  },
  watch: {
    "newUser.counseling_schools_type"() {
      const { counseling_schools_type } = this.newObjectDetail;
      const year = Number(moment().format("YYYY"));
      const month = Number(moment().format("M"));
      // 升大學
      if (counseling_schools_type === 1) {
        if (month < 7) {
          this.graduateSelected = String(year + 1);
        } else {
          this.graduateSelected = String(year + 1 + 1);
        }
        // 升研究所
      } else if (counseling_schools_type === 2) {
        if (month < 7) {
          this.graduateSelected = String(year - 1);
        } else {
          this.graduateSelected = String(year - 1 + 1);
        }
      }
    }
  },
  computed: {
    ...mapState("registerDetails", ["isValidStudentEmail", "isSubmitError"]),
    ...mapState("user", ["isDuplicate"]),
    ...mapState("user", ["profile", "isDuplicate", "token"]),
    ...mapState("registerDetails", ["isBinding"]),
    rules() {
      return {
        first_name: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ],
        last_name: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ],
        username: [
          {
            required: true,
            message: this.$t("registerDetail.usernameRequiredError")
          }
        ]
      };
    }
  }
};
</script>

<style scoped>
.titleLine {
  text-align: center;
}
.titleStyle {
  display: block;
  overflow: auto;
}
.primaryColor {
  color: #42a16a;
}
.select {
  font-size: 30px;
}
.textField {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.box-card {
  display: inline-block;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.input-icon {
  width: 22px;
  height: 22px;
  margin-top: 7px;
  background-size: cover;
}
.icon-account {
  mask: url("../../assets/svg/account.svg") no-repeat;
}
.icon-email {
  mask: url("../../assets/svg/email.svg") no-repeat;
}
.icon-check {
  mask: url("../../assets/svg/check-circle.svg") no-repeat;
}
.icon-domain {
  mask: url("../../assets/svg/domain.svg") no-repeat;
}
.icon-phone {
  mask: url("../../assets/svg/phone.svg") no-repeat;
}
.icon-home {
  mask: url("../../assets/svg/home.svg") no-repeat;
}
.icon-facebook {
  mask: url("../../assets/svg/facebook.svg") no-repeat;
}
.icon-line {
  mask: url("../../assets/svg/line-brands.svg") no-repeat;
}
.icon-wechat {
  mask: url("../../assets/svg/wechat.svg") no-repeat;
}
.icon-skype {
  mask: url("../../assets/svg/skype.svg") no-repeat;
}
.icon-check {
  mask: url("../../assets/svg/check-circle.svg") no-repeat;
}
</style>
